import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import "./index.scss";

class Header extends Component {

  toggleMenu = () => {
    document.getElementsByTagName('body')[0].classList.toggle('select')
  }

  render() {
    return (
      <div className="myHeader">
        <header id="header" className="row">
          <div className="container">
            <div className="col-md-3 logo">
              <h1>
                <NavLink
                  to="/"
                  title="桃園市政府能源資訊平台：回首頁"
                  style={{ display: "block" }}
                >
                  <img alt="桃園市政府能源資訊平台：回首頁" src="./img/new/logo.png" />
                  <strong style={{ display: "none" }}>桃園市政府能源資訊平台</strong>
                </NavLink>
              </h1>
            </div>
            <div className="col-md-9">
              <div id="menu">
                <ul>
                  <li onClick={() => this.toggleMenu()}>
                    <NavLink to="/" exact={true}>首頁</NavLink>
                  </li>
                  <li onClick={() => this.toggleMenu()}>
                    <NavLink to="/billboard">能源布告欄</NavLink>
                  </li>
                  <li onClick={() => this.toggleMenu()}>
                    <NavLink to="/subsidy">補助資訊</NavLink>
                  </li>
                  <li onClick={() => this.toggleMenu()}>
                    <NavLink to="/promotion">能源推廣</NavLink>
                  </li>
                  <li onClick={() => this.toggleMenu()}>
                    <NavLink to="/energy-map/condition">能源地圖</NavLink>
                  </li>
                  <li onClick={() => this.toggleMenu()}>
                    <NavLink to="/large-consumer">用電大戶</NavLink>
                  </li>
                  <li onClick={() => this.toggleMenu()}>
                    <NavLink to="/info">更多資訊</NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <nav id="menu-btn" onClick={() => this.toggleMenu()}>
              <a className="open" title="手機版打開選單">
                <i className="fas fa-bars"></i>
              </a>
              <a className="close" title="手機版關閉選單">
                <i className="fas fa-times"></i>
              </a>
            </nav>
          </div>
        </header >
      </div >
    );
  }
};
export default Header;
