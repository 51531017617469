import React, { useState, useEffect } from "react";
// import axios from 'axios';
import { oldApiRequest } from "../../utils/api";
import { getWording } from "../../utils/wordingSystem";
import { scrollToTop } from "../../utils/methods";
import {
  MediaQueryDesktop,
  MediaQueryMobile,
} from "../../components/MediaQuery";
import { Link } from "react-router-dom";
import * as R from "ramda";
import "./index.scss";

const Footer = () => {
  const { unit_name, office_name } = getWording("common");
  const {
    sitemap,
    address,
    address_map_url,
    phone,
    phone_url,
    office_hour,
    copyright,
    fb_url,
    youtube_url,
    email_url,
  } = getWording("footer");

  const [splitedSitemap, setSplitedSitemap] = useState([]);
  const [fetchedMenu, setFetchedMenu] = useState({});
  const [mobileExtend, setMobileExtend] = useState(-1);

  useEffect(() => {
    let sitemapClone = R.clone(sitemap);
    let newSitemap = [];

    sitemapClone.forEach((subject) => {
      if (typeof subject.items == "object" && subject.items.length > 4) {
        const splited = R.splitEvery(
          4,
          subject.items.filter((_) => _.url)
        );
        splited.forEach((items) => {
          newSitemap.push({ ...subject, items });
        });
      } else if (typeof subject.items == "string") {
        oldApiRequest
          .get(subject.items)
          .then(({ data }) => {
            let newMenu = { ...fetchedMenu };
            newMenu[subject.items] = data;
            setFetchedMenu(newMenu);
          })
          .catch((err) => { });
        newSitemap.push(subject);
      } else {
        newSitemap.push(subject);
      }
    });

    setSplitedSitemap(newSitemap);
  }, []);

  const LinkButton = ({ to, children }) =>
    to.slice(0, 1) === "/" ? (
      <Link to={to}>{children}</Link>
    ) : (
      <a href={to} target="_blank" rel="noreferrer">
        {children}
      </a>
    );

  const Links = ({ subject }) => (
    <ul className="links">
      {typeof subject.items == "string" &&
        fetchedMenu[subject.items]?.map?.((_) => (
          <li className="link" key={_.title}>
            <LinkButton to={`${subject.url}#${_.slug}`}>{_.title}</LinkButton>
          </li>
        ))}
      {typeof subject.items == "object" &&
        subject.items.map((link) => (
          <li className="link" key={link.label}>
            <LinkButton to={link.url}>{link.label}</LinkButton>
          </li>
        ))}
    </ul>
  );

  const SNLink = ({ href, children, faClassName }) => (
    <a className="icon-link" target="_blank" rel="noreferrer" href={href}>
      <i className={faClassName}></i>
      {children}
    </a>
  );

  return (
    <>
      <button className="footer-btn-page-top" onClick={() => scrollToTop(true)}>
        Page Top <i className="far fa-chevron-up"></i>
      </button>

      <footer className="footer container">
        <div className="footer-inner container-inner">
          <div className="informations">
            <div className="side-block -left">
              <div className="top">
                <h3>{unit_name + office_name}</h3>
                <p>
                  <a href={address_map_url} target="_blank" rel="noreferrer">
                    {address}
                  </a>
                </p>
                <p>
                  <a href={phone_url}>{phone}</a>
                </p>
              </div>
              <div className="bottom">
                <p>{office_hour}</p>
              </div>
            </div>
            <div className="side-block -right">
              <div className="top">
                <SNLink href={fb_url} faClassName="fab fa-facebook-square">
                  桃園綠能辦公室Facebook粉絲團
                </SNLink>

                <SNLink href={youtube_url} faClassName="fab fa-youtube">
                  桃園綠能辦公室Youtube頻道
                </SNLink>

                <SNLink href={email_url} faClassName="fas fa-envelope">
                  寫email給桃園綠能辦公室
                </SNLink>
              </div>
              <div className="bottom">
                <p>{copyright}</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
