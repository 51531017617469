import React, { lazy } from "react";
import { Route } from "react-router-dom";
import { getWording } from "./utils/wordingSystem";
import Breadcrumb from "./components/Breadcrumb";

const HomePage = lazy(() => import("./pages/HomePageNew"));
const HomePageDist = lazy(() => import("./pages/HomePageDist"));
const SettingAndSubsidize = lazy(() => import("./pages/SettingAndSubsidize"));
const PowerSaving = lazy(() => import("./pages/PowerSaving"));
const ArticlePage = lazy(() => import("./pages/ArticlePage"));
const DownloadsPage = lazy(() => import("./pages/DownloadsPage"));
const LinksPage = lazy(() => import("./pages/LinksPage"));
const FinancingInfoPage = lazy(() => import("./pages/FinancingInfoPage"));
const CostCalculatorPage = lazy(() => import("./pages/CostCalculatorPage"));
const QnAPage = lazy(() => import("./pages/QnAPage"));
const BillboardPage = lazy(() => import("./pages/BillboardPage"));
const NewsPage = lazy(() => import("./pages/NewsPage"));
const InfoPage = lazy(() => import("./pages/InfoPage"));
const PromotionPage = lazy(() => import("./pages/PromotionPage"));
const SubsidyPage = lazy(() => import("./pages/SubsidyPage"));
const LargeConsumerPage = lazy(() => import("./pages/LargeConsumerPage"));
const GreenPowerPage = lazy(() => import("./pages/GreenPowerPage"));
const LargeElectricityUsersPage = lazy(() =>
  import("./pages/LargeElectricityUsersPage")
);
const EnergyMapPage = lazy(() => import("./pages/EnergyMapPage"));
const HelperPage = lazy(() => import("./pages/HelperPageNew"));
const DashboardPage = lazy(() => import("./pages/DashboardPage"));
const ManagerDashboardPage = lazy(() => import("./pages/ManagerDashboardPage"));

export default (_) => {
  const subjects = getWording("subjects");
  const power_saving = getWording("power_saving");
  const downloads_page = getWording("downloads_page");
  const links_page = getWording("links_page");
  const financing_info_page = getWording("financing_info_page");
  const cost_calculator_page = getWording("cost_calculator_page");
  const question_and_answer_page = getWording("question_and_answer_page");
  const billboard_page = getWording("billboard_page");
  const green_power_page = getWording("green_power_page");
  const helper_page = getWording("helper_page");
  const dashboard_page = getWording("dashboard_page");
  return (
    <>
      <Route exact path="/" component={HomePage} />
      <Route exact path="/dist" component={HomePageDist} />

      {/* <Route
        exact
        path={[`/${subjects[1].slug}`, `/${subjects[1].slug}/:subject`]}
        component={SettingAndSubsidize}
      /> */}
      {/* <Route
        exact
        path={[`/${subjects[2].slug}`, `/${subjects[2].slug}/:subject`]}
        component={PowerSaving}
      /> */}
      <Route
        exact
        path={`/billboard/knowledge/:articleSlug`}
        render={(routeProps) => (
          <ArticlePage
            routeProps={routeProps}
            articleApiUrl={power_saving.subject_4.article_api_url}
          />
        )}
      />

      <Route exact path={`/${downloads_page.slug}`} component={DownloadsPage} />
      <Route exact path={`/${links_page.slug}`} component={LinksPage} />
      <Route
        exact
        path={`/${financing_info_page.slug}`}
        component={FinancingInfoPage}
      />
      <Route
        exact
        path={`/${cost_calculator_page.slug}`}
        component={CostCalculatorPage}
      />
      <Route
        exact
        path={`/${question_and_answer_page.slug}`}
        component={QnAPage}
      />
      <Route exact path={[`/billboard`, `/billboard/:subject`]} component={NewsPage} />
      <Route exact path={`/billboard/${billboard_page.slug}`} component={BillboardPage} />
      <Route
        exact
        path={`/${billboard_page.slug}/:articleSlug`}
        render={(routeProps) => (
          <ArticlePage
            routeProps={routeProps}
            articleApiUrl={billboard_page.article_api_url}
          />
        )}
      />
      <Route
        exact
        path={`/${green_power_page.slug}`}
        component={GreenPowerPage}
      />
      <Route
        exact
        path={`/${green_power_page.slug}/:articleSlug`}
        render={(routeProps) => (
          <ArticlePage
            routeProps={routeProps}
            articleApiUrl={green_power_page.article_api_url}
          />
        )}
      />
      <Route
        exact
        path={`/${subjects[3].slug}`}
        component={LargeElectricityUsersPage}
      />
      <Route
        exact
        path={[`/${subjects[10].slug}`, `/${subjects[10].slug}/:key`]}
        component={EnergyMapPage}
      />
      <Route exact path={[`/${helper_page.slug}`, `/${helper_page.slug}/:subject`]} component={HelperPage} />
      <Route
        exact
        path={[
          `/${dashboard_page.slug}`,
          `/${dashboard_page.slug}/:step/:reportid`,
        ]}
        component={DashboardPage}
      />

      <Route exact path={[`/info`, `/info/:subject`]} component={InfoPage} />
      <Route exact path={[`/promotion`, `/promotion/:subject`]} component={PromotionPage} />
      <Route exact path={[`/subsidy`, `/subsidy/:subject`]} component={SubsidyPage} />
      <Route exact path={[`/large-consumer`, `/large-consumer/:subject`]} component={LargeConsumerPage} />
    </>
  );
};

export const setupBreadcrumb = () => {
  const common = getWording("common");
  const subjects = getWording("subjects");
  const setting_and_subsidize = getWording("setting_and_subsidize");
  const power_saving = getWording("power_saving");
  const downloads_page = getWording("downloads_page");
  const links_page = getWording("links_page");
  const financing_info_page = getWording("financing_info_page");
  const cost_calculator_page = getWording("cost_calculator_page");
  const question_and_answer_page = getWording("question_and_answer_page");
  const billboard_page = getWording("billboard_page");
  const green_power_page = getWording("green_power_page");
  const energy_map_page = getWording("energy_map_page");
  const helper_page_new = getWording("helper_page_new");

  Breadcrumb.setupTitlePrefix(common["unit_name"] + common["office_name"]);

  // layer 1
  subjects.forEach((item) => {
    Breadcrumb.pushRoutesInfo(item.slug, item.label);
  });

  // layer 2 - 能源設置和補助
  setting_and_subsidize.subjects.forEach((item) => {
    Breadcrumb.pushRoutesInfo(item.slug, item.label);
  });

  // layer 2 - 節能小幫手
  power_saving.subjects.forEach((item) => {
    Breadcrumb.pushRoutesInfo(item.slug, item.label);
  });

  // layer 2 - 更多資訊
  Breadcrumb.pushRoutesInfo(downloads_page.slug, downloads_page.title);
  Breadcrumb.pushRoutesInfo(links_page.slug, links_page.title);
  Breadcrumb.pushRoutesInfo(
    financing_info_page.slug,
    financing_info_page.title
  );
  Breadcrumb.pushRoutesInfo(
    cost_calculator_page.slug,
    cost_calculator_page.title
  );
  Breadcrumb.pushRoutesInfo(
    question_and_answer_page.slug,
    question_and_answer_page.title
  );
  Breadcrumb.pushRoutesInfo(billboard_page.slug, billboard_page.title);
  Breadcrumb.pushRoutesInfo(green_power_page.slug, green_power_page.title);

  // layer 2 - 能源地圖
  energy_map_page.top_navbar.forEach(({ key, value }) => {
    Breadcrumb.pushRoutesInfo(key, value);
  });

  // layer 2 - 能源與推光
  helper_page_new.subjects.forEach((item) => {
    Breadcrumb.pushRoutesInfo(item.slug, item.label);
  });
};

export const singlePage = (_) => {
  return (
    <>
      <Route
        exact
        path={`/single/manager/:detailid`}
        component={ManagerDashboardPage}
      />
    </>
  );
};
